<template lang="pug">
v-container
  h1 Willkommen!
  p Dies ist der virtuelle Visitenkarten-Service von PIT.at.
</template>

<script>
export default {
  created() {
    var id = this.$route.query.id;
    if (id) {
      this.$router.replace('/' + id);
    }
  }
};
</script>