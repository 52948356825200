import {createRouter, createWebHistory} from 'vue-router';
import Home from './views/HomeView.vue';
import Card from './views/CardView.vue';

var routes = [{
  path:'/',
  component:Home,
  meta:{public:true, title:'Startseite'}
}, {
  path:'/profile',
  component:() => import('./views/ProfileView.vue'),
  meta:{title:'Profil'}
}, {
  path:'/admin',
  redirect:'/admin/person'
}, {
  path:'/admin/person',
  component:() => import('./views/PersonView.vue'),
  meta:{title:'Personen'}
}, {
  path:'/admin/services',
  component:() => import('./views/ServicesView.vue'),
  meta:{title:'Dienste'}
}, {
  path:'/admin/links',
  component:() => import('./views/LinksView.vue'),
  meta:{title:'Link-Liste'}
}, {
  path:'/:name',
  component:Card,
  meta:{public:true, title:'Visitenkarte'}
}];

export default createRouter({
  history:createWebHistory(),
  routes
});