<template lang="pug">
pitifyApp(:mainMenu="mainMenu" :accountMenu="accountMenu" :hideMenu="!api.user")
a.imprint(href="https://pit.at/impressum/" target="_blank") Impressum
</template>

<script>
export default {
  data() {
    return {
      mainMenu:[
        'Hauptmenü',
        {icon:'mdiHomeCityOutline', title:'Startseite', to:'/'}
      ],
      accountMenu:[
        {icon:'mdiLogout', title:'Abmelden', click:() => this.api.logout()}
      ]
    };
  },
  created() {
    if (this.api.user?.level) {
      this.mainMenu.push(
        {icon:'mdiAccount', title:'Mein Profil', to:'/profile'}
      );
    }
    if (this.api.user?.level >= 10) {
      this.mainMenu.push(
        'Admin',
        {icon:'mdiAccountGroup', title:'Personen', to:'/admin/person'},
        {icon:'mdiWeb', title:'Dienste', to:'/admin/services'},
        {icon:'mdiLinkVariant', title:'Link-Liste', to:'/admin/links'}
      );
    }
  }
};
</script>

<style lang="stylus">
.links
  .logo
    width:200px
    height:200px
    margin:10px 10px -7px 10px
    border-radius:3%
  a
    text-decoration:none
    border-radius:2%
    transition:box-shadow .3s
    box-shadow:0 0 5px rgba(0,0,0, 0)
  a:hover
    box-shadow:0 0 5px rgba(0,0,0, .3)
  .v-card-title
    text-align:center
.imprint
  display:block
  position:fixed
  bottom:0
  width:100%
  text-align:center
  color:#666
  padding:3px 0
  text-decoration:none
</style>