<template lang="pug">
#card
  v-overlay.align-center.justify-center(:model-value="loading")
    v-progress-circular(color="primary" indeterminate size="64")

  v-container(v-if="data.name")
    .head.d-flex.justify-center.align-center.mt-2
      v-avatar(size="120" color="blue" :image="data.imageUrl || undefined" :icon="ICON.mdiAccount")
      .pr-2
        h2.text-h5 {{data.name}}
        .text-subtitle-1 {{data.description}}

    v-divider.mt-5.mb-5

    .links.d-flex.flex-wrap.justify-center.mb-5
      a(v-if="data.vcard" :href="'data:text/vcard;base64,'+data.vcard" :download="vcfName")
        v-card(width="220")
          img.logo.cover(:src="contactJpg" alt="Adressbuch")
          v-card-title Kontakt speichern
      a(href="https://pit.at/Kontakt/" target="_blank")
        v-card(width="220")
          img.logo.cover(:src="supportJpg" alt="Support")
          v-card-title PIT.at Support
      a(v-for="entry in data.entries" :href="entry.value" target="_blank")
        v-card(width="220")
          img.logo(:src="'data:image/png;base64,'+entry.logo" :alt="'Logo '+entry.name")
          v-card-title {{entry.name}}

  v-container(v-if="notFound")
    .text-center.mt-6
      v-icon(:icon="ICON.mdiEmoticonSadOutline" size="100")
      h1.ma-6 Nicht gefunden...
</template>

<script>
import contactJpg from '../img/kontakt.jpg';
import supportJpg from '../img/support.jpg';

export default {
  data() {
    return {
      loading:true,
      notFound:false,
      data:{},
      contactJpg,
      supportJpg
    };
  },
  computed:{
    vcfName() {
      return this.data.name.replace(/\W/g, '') + '.vcf';
    }
  },
  created() {
    this.loading = this.api('card.load', {path:this.$route.params.name}, data => {
      this.data = data;
      document.title = data.name + ' - Visitenkarte';
    }, err => {
      if (err === 'NOT_FOUND') {
        this.notFound = true;
      }
    }).loading;
  }
};
</script>

<style lang="stylus">
#card
  .d-flex
    gap:1rem
  .cover
    object-fit:cover
  .v-card
    zoom:.8

@media (max-width: 600px)
  #card
    .head
      flex-direction:column
      text-align:center
    .v-card
      zoom:.666
</style>