import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import api from '../pitify/src/api';
import vuetify from '../pitify/src/vuetify';
import ui from '../pitify/src/ui';

var app = createApp(App).use(api);

api.init(() => {
  api.setupRouter(router);
  app.use(router).use(vuetify).use(ui).mount('#app');
});